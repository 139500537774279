import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportRoutingModule } from './import-routing.module';
import { ImportComponent } from './import.component';
import { ImportCreateComponent } from './import-create/import-create.component';
import { ImportEditComponent } from './import-edit/import-edit.component';
import { ImportListComponent } from './import-list/import-list.component';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { ImportFormatsResolve } from './shared/import-formats.resolve';
import { MaterialInputModule } from '../../modules/@material-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportFeedResolve } from './shared/import-feed.resolve';
import { ImportFeedReportResolve } from './shared/import-feed-report.resolve';
import { ImportFeedHousingResolve } from './shared/import-feed-housing.resolve';
import { ImportEditReportComponent } from './import-edit/import-edit-report/import-edit-report.component';
import { ImportEditHousingComponent } from './import-edit/import-edit-housing/import-edit-housing.component';
import { ImportEditConfirmComponent } from './import-edit/import-edit-confirm/import-edit-confirm.component';
import { ImportEditHistoryComponent } from './import-edit/import-edit-history/import-edit-history.component';
import { ImportPeriodicResolve } from './shared/import-periodic.resolve';
import { ImportModificationResolve } from './shared/import-modification.resolve';
import { ImportFeedObjectsResolve } from './shared/import-feed-objects.resolve';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import { ImportStatusesResolve } from './shared/import-statuses.resolve';
import { RouterModule } from '@angular/router';
import { ImportEditRealizationComponent } from './import-edit/import-edit-realization/import-edit-realization.component';
import { ImportFeedRealizationResolve } from './shared/import-feed-realization.resolve';
import {SharedModule} from '../shared.module';
import {ImportEditService} from './import-edit/import-edit.service';
import {ImportEditCommerceComponent} from './import-edit/import-edit-commerce/import-edit-commerce.component';
import {ImportFeedCommerceResolve} from './shared/import-feed-commerce.resolve';


@NgModule({
  imports: [
    CommonModule,
    ImportRoutingModule,
    DataTableModule,
    MaterialInputModule,
    FormsModule,
    ReactiveFormsModule,
    PreloaderModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    ImportComponent,
    ImportCreateComponent,
    ImportEditComponent,
    ImportListComponent,
    ImportEditReportComponent,
    ImportEditHousingComponent,
    ImportEditConfirmComponent,
    ImportEditHistoryComponent,
    ImportEditRealizationComponent,
    ImportEditCommerceComponent,
  ],
  providers: [
    ImportFormatsResolve,
    ImportFeedResolve,
    ImportFeedReportResolve,
    ImportFeedHousingResolve,
    ImportFeedObjectsResolve,
    ImportModificationResolve,
    ImportPeriodicResolve,
    ImportStatusesResolve,
    ImportFeedRealizationResolve,
    ImportFeedCommerceResolve,
    ImportEditService,
  ]
})
export class ImportModule {
}
