import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AnalyticRoutingModule} from './analytic-routing.module';
import {MaterialInputModule} from '../../modules/@material-input';
import {TableHelpersModule} from '../../shared/tables';

import * as c from './components';
import * as r from './resolvers';
import * as s from './shared';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgChartsModule} from 'ng2-charts';
import {SharedModule} from '../../shared/shared.module';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {StagesDotsModule} from '../../modules/@stages-dots/stages-dots.module';
import {RightWindowModule} from '../../shared/right-window/right-window.module';
import {PreloaderModule} from '../../modules/@preloader/preloader.module';


@NgModule({
  imports: [
    CommonModule,
    AnalyticRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    TableHelpersModule,
    NgChartsModule,
    SharedModule,
    PaginatorModule,
    StagesDotsModule,
    RightWindowModule,
    PreloaderModule,
  ],
  declarations: [
    c.AnalyticIndexComponent,
    c.AnalyticWrapperComponent,
    s.ReportFilterComponent,
    c.ReportCommonComponent,
    c.ReportCompareComponent,
    c.ReportKpiComponent,
    c.ReportKpiTableComponent,
    c.ReportAttractionActivateComponent,
    c.ReportAttractionActivateTableComponent,
    c.GetActivityComponent,
    c.GetActivityTableComponent,
    c.GetCalendarSummaryTableComponent,
    c.GetRealtySummaryTableComponent,
    c.GetCalendarSummaryOrganisationTableComponent,
    c.GetActivityComplexIdComponent
  ],
  providers: [
    r.IndexResolve,
    r.ReportCommonResolve,
    r.ReportCompareResolve,
    r.ReportKpiResolve,
    r.ReportKpiTableResolve,
    r.ReportAttractionActivateResolve,
    r.ReportAttractionActivateTableResolve,
    r.GetSubActivityResolve,
    r.GetComplexActivityResolve,
    r.GetSubUserActivityResolve,
    r.GetSubActivityOneOrganisationResolve,
    r.GetActivityTableResolve,
    r.GetCalendarSummaryTableResolve,
    r.GetRealtySummaryTableResolve,
    r.GetCalendarSummaryOrganisationTableResolve,
    r.GetActivityComplexIdResolve
  ]
})
export class AnalyticModule { }
