import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stages-dots',
  templateUrl: './stages-dots.component.html',
  styleUrls: ['./stages-dots.component.scss']
})
export class StagesDotsComponent implements OnInit {
  @Input() public data: any;
  @Input() public info: any = null;
  @Input() public cssClass = '';
  @Input() public miniView = false;
  @Input() public manager_fio;
  @Input() public manager_phone;
  public rightWindow = false;
  public dotArray: Array<string>;

  constructor() { }

  ngOnInit() {
    this.dotArray = new Array(this.data.bp_stages_count).fill('');
  }

  public onClosedRight() {
    this.rightWindow = false;
  }
}
