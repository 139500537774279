import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../breadcrumbs/services';

@Component({
  templateUrl: './import-create.component.html',
  styleUrls: ['./import-create.component.scss']
})
export class ImportCreateComponent implements OnInit {

  public formGroup: UntypedFormGroup;
  public format_list: any;
  public loading = false;
  public error: any;

  constructor(public http: HttpClient, public fb: UntypedFormBuilder, private goBack: GoBackButtonService, private b: BreadcrumbsService,
              public notify: NotificationService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.formGroup = this.fb.group({
      'url': [{value: '', disabled: false}, Validators.required],
      'specification_id': [{value: '', disabled: false}, Validators.required],
    });
    this.format_list = this.activatedRoute.snapshot.data['formats'];

  }

  ngOnInit() {
    this.goBack.header = '<h1>Новый импорт</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/import', caption: 'Автоматический импорт'},
    ]);
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    this.loading = true;
    this.error = false;
    this.formGroup.disable();
    const data = {
      'url': this.formGroup.value.url,
      'specification_id': +this.formGroup.value.specification_id,
    };

    this.http.post('Import.setting_create', data).subscribe(
      (response) => {
        this.onSuccess(response);
        this.loading = false;
        this.formGroup.enable();
        this.router.navigate(['/import/edit', response]);
      },
      (error) => {
        this.loading = false;
        this.formGroup.enable();
        // this.onError(error);
      }
    );
  }

  onSuccess(response?) {
    this.notify.notifySuccess('Поздравляем', 'Фид успешно создан', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

}
