import { Component, Input, EventEmitter, Output, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  /* tslint:disable-next-line:component-selector-prefix component-selector-name */
  selector: 'app-datatable-headline',
  templateUrl: './datatable-headline.component.html'
})
export class DataTableHeadlineComponent implements OnInit {
  @Input() public items_per_page_vars: Array<number>;
  @Input() public items_per_page = 10;
  @Input() public global_search_string = '';
  @Input() public templateLeft: TemplateRef<any>;
  @Input() public templateRight: TemplateRef<any>;

  @Output() public items_per_page_change: EventEmitter<number> = new EventEmitter<number>();
  @Output() public global_search_change: EventEmitter<string> = new EventEmitter();

  public formGroup: UntypedFormGroup;
  public showed_lines = 0;

  constructor(public fb: UntypedFormBuilder) {
    this.formGroup = this.fb.group({
      'items_per_page': ['' + this.items_per_page],
      'search': ['']
    });
    // this.formGroup.get('search').valueChanges.debounceTime(500).subscribe(data => {
    //    this.global_search_change.emit(data);
    // });
  }

  ngOnInit() {
    if (this.global_search_string) {
      this.formGroup.patchValue({'search': this.global_search_string});
    }
  }

  onPerPageChange() {
    this.items_per_page_change.emit(+this.formGroup.value.items_per_page);
  }

  clickOnSubmit(value) {
    this.global_search_change.emit(value);
  }
}
