<div class="form__group" [class.mb-0]="withMb0">
  <label class="form__checkbox" [ngClass]="'form__checkbox_'+icon" [class.form__checkbox_border]="border">
    <input type="checkbox" (change)="onChanges()" [checked]="current_value" [disabled]="disabled">
    <span *ngIf="current_value && disabled; else default" class="form__checkbox__wrapper__true">
      <span class="form__checkbox__wrapper__toggler icon" [ngClass]="[icon]"></span>
    </span>
    <ng-template #default>
        <span class="form__checkbox__wrapper">
      <span class="form__checkbox__wrapper__toggler icon" [ngClass]="[icon]"></span>
    </span>
    </ng-template>
    <span class="form__checkbox__content"><ng-content></ng-content></span>
  </label>
</div>
