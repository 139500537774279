import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-material-input-checkbox',
  templateUrl: './material-input-checkbox.component.html',
  styles: [
    `.caption {color: #727272; line-height: 1.2;}`,
    `.form__checkbox {width: fit-content;}`
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputCheckboxComponent),
      multi: true
    }
  ]
})
export class MaterialInputCheckboxComponent implements ControlValueAccessor {
  @Input() public require = false;
  @Input() public cssClass = '';
  @Input() public icon = 'icon_done_mark';
  @Input() public disabled = false;
  @Input() public border = false;
  @Input() public withMb0 = false;
  // для сохранения обратной совместимости, оставляем в дефолте false, хотя по-хорошему
  // здесь должен быть null для корректной работы валидатора required
  @Input() public falseValue = false;

  @Output() changeCurrentValue = new EventEmitter<boolean>();

  private _current_value;

  public get current_value(): boolean {
    return this._current_value;
  }

  public set current_value(value: boolean) {
    this._current_value = value || this.falseValue;
    this.propagateChange(this._current_value);
  }

  propagateChange = (_: any) => {};

  onChange() {
    this.current_value = !this.current_value;
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  onChanges() {
    if (!this.disabled) {
      this.current_value = !this.current_value;
      this.changeCurrentValue.emit(this.current_value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

}
