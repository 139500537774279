<ng-container *ngIf="this.multisortEvent">
  <tr class="d-none d-lg-table-row">
    <th [attr.colspan]="columns.length">
      <p class="mb-1">Для сортировки по нескольким столбцам <strong>зажмите</strong> клавишу <strong>shift</strong></p>
    </th>
  </tr>
</ng-container>

<tr>
  <th *ngFor="let column of columns" [ngStyle]="{'width': (column.width) ? column.width : 'auto'}">
    <!--mobile-->

    <ng-container *ngIf="!embededService">
      <ng-container *ngIf="column.sortable || column.templateHead">
        <div class="d-flex w-100 d-md-none justify-content-between c-pointer" mobile-sort [mobile_sort_caption]="column.caption" [mobile_sort_template]="column.templateHead" [mobile_sort_exist]="column.sortable" [mobile_sort_sort]="sort[column.name]" (mobile_sort_change)="mobileSortChange($event, column.name)">
          {{column.caption}}
          <i *ngIf="column.sortable" class="icon" [ngClass]="{'icon_new-sort-none': !sort[column.name],
                                    'icon_new-sort-down': 'asc' == sort[column.name],
                                    'icon_new-sort-up': 'desc' == sort[column.name]}"></i>
          <div class="mobile-sort-container">
            <ng-container #mobileSortContainer></ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!column.sortable &&  !column.templateHead">
        <div class="d-flex w-100 d-md-none justify-content-between c-pointer">
          {{column.caption}}
        </div>
      </ng-container>
    </ng-container>

    <!--desktop-->
    <div class="d-none d-none-sm-fix d-md-block" [ngClass]="{'d-block': embeddedService}">
      <span *ngIf="!column.sortable && !column.templateHead">{{column.caption}}</span>
      <a *ngIf="column.sortable && !column.templateHead" class="caption_sort"
         href="javascript:void(0)" (click)="sortChange(column.name)">
        {{column.caption}}
        <i class="icon" [ngClass]="{'icon_new-sort-none': !sort[column.name],
                                  'icon_new-sort-down': 'asc' == sort[column.name],
                                  'icon_new-sort-up': 'desc' == sort[column.name]}"></i>
      </a>
      <div *ngIf="column.templateHead" class="row no-gutters align-items-center flex-nowrap">
        <div class="col table__input">
          <ng-template
            *ngIf="column.templateHead"
            [ngTemplateOutlet]="column.templateHead"
            [ngTemplateOutletContext]="{column: column}"></ng-template>
        </div>
        <div class="col-auto sort-block" *ngIf="column.sortable">
          <a href="javascript:void(0)" (click)="sortChange(column.name)">
            <i class="icon" [ngClass]="{'icon_new-sort-none': !sort[column.name],
                                      'icon_new-sort-down': 'asc' == sort[column.name],
                                      'icon_new-sort-up': 'desc' == sort[column.name]}"></i>
          </a>
        </div>
      </div>
    </div>
  </th>
</tr>
