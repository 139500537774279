<div class="import-create">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <div class="row no-gutters gutters-4 align-items-center">
      <div class="col-9">
        <app-material-input formControlName="url" [placeholder]="'https://site.ru/'" [require]="true">
          URL фида
        </app-material-input>
      </div>
      <div class="col-3">
        <app-material-select2
          formControlName="specification_id"
          [placeholder]="'-- Не выбрано --'"
          [caption]="'Формат'"
          [require]="true">
          <option [value]="''">-- Не выбрано --</option>
          <option *ngFor="let format of format_list"
                  [value]="+format.id">{{format.caption}}</option>
        </app-material-select2>
      </div>
    </div>
    <div class="row no-gutters gutters-4 align-items-center mt-2">
      <div class="col">
        <div class="c-red text-normal" *ngIf="error">
          {{error}}
        </div>
      </div>
      <div class="col-auto">
        <ng-container *ngIf="loading">
          <i class="fa fa-spin fa-spinner mr-2"></i>
          <span class="pull-right text-normal">Анализируем…</span>
        </ng-container>
      </div>
      <div class="col-auto">
        <button (click)="saveForm()" type="button" [disabled]="loading"
                class="button pull-right" [ngClass]="{'button_disabled': loading}">
          Далее >
        </button>
      </div>
    </div>
  </form>
</div>
