
export class User {

  public is_anonymous = false;
  public is_admin = false;

  public token_type = null;
  public wizard_step = null;
  public access_level = null;
  public fio = null;
  public name = null;
  public fam = null;
  public firm_id = null;
  public id = null;
  public is_boss = null;
  public is_subagent = null;
  public pcaption = null;
  public ucaption_rekl = null;
  public role_id = null;
  public role_order = null;
  public status_id = null;
  public ucaption = null;
  public avatar = '/assets_common/images/Avatar_empty.svg';
  public organisation_logo = null;
  public access = [];
  public access_crm: any = [];
  public help_page_viewed = [];
  public count_request_employees = null;
  public view_alert_logo = null;

  public is_frame_user = false;

  constructor(public username: string, public session_id: string, public email: string) { }

  public get is_defective() {
    return this.token_type === 'wwwwizard' || this.wizard_step !== null;
  }

  public get is_privileged() {
    return this.is_admin || this.is_boss;
  }

  public static from_json(params: string): User {
    const user_data = JSON.parse(params);
    const user = new User(user_data.username, user_data.session_id, user_data.email);
    user.access_level = user_data.access_level;
    user.fio = user_data.fio;
    user.name = user_data.name;
    user.fam = user_data.fam;
    user.firm_id = user_data.firm_id;
    user.id = user_data.id;
    user.is_boss = user_data.is_boss;
    user.is_subagent = user_data.is_subagent;
    user.pcaption = user_data.pcaption;
    user.ucaption_rekl = user_data.ucaption_rekl;
    user.role_id = user_data.role_id;
    user.role_order = user_data.role_order;
    user.status_id = user_data.status_id;
    user.ucaption = user_data.ucaption;
    user.avatar = user_data.avatar;
    user.organisation_logo = user_data.organisation_logo;
    user.access = user_data.access;
    user.access_crm = user_data.access_crm;
    user.wizard_step = user_data.wizard_step;
    user.token_type = user_data.token_type;
    user.help_page_viewed = user_data.help_page_viewed;
    user.count_request_employees = user_data.count_request_employees;
    user.email = user_data.email;
    user.view_alert_logo = user_data.view_alert_logo;

    return user;
  }

  public to_json(): string {
    const data = {
      'username': this.username,
      'session_id': this.session_id,
      'access_level': this.access_level,
      'fio': this.fio,
      'name': this.name,
      'fam': this.fam,
      'firm_id': this.firm_id,
      'id': this.id,
      'is_boss': this.is_boss,
      'is_subagent': this.is_subagent,
      'pcaption': this.pcaption,
      'ucaption_rekl': this.ucaption_rekl,
      'role_id': this.role_id,
      'role_order': this.role_order,
      'status_id': this.status_id,
      'ucaption': this.ucaption,
      'avatar': this.avatar,
      'organisation_logo': this.organisation_logo,
      'access': this.access,
      'access_crm': this.access_crm,
      'wizard_step': this.wizard_step,
      'token_type': this.token_type,
      'help_page_viewed': this.help_page_viewed,
      'count_request_employees': this.count_request_employees,
      'email': this.email,
      'view_alert_logo': this.view_alert_logo,
    };
    return JSON.stringify(data);
  }

  public setProperty(key, value) {
    if (this.hasOwnProperty(key)) {
      this[key] = value;
    }
  }
}

export class AnonymousUser extends User {
  public is_anonymous = true;
  public is_admin = false;

  constructor() {
    super('Anonymous', '', null);
  }
}
