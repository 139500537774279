import {AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '../../services/http.service';
import { EntityCreateComponent } from './entity-create/entity-create.component';
import { OverlayWindowService } from '../single-window/shared/overlay-window.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {GoBackButtonService} from '../../services/go-back-button.service';


@Component({
  selector: 'app-entity',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss']
})
export class EntityListComponent extends DataTableParamComponent implements OnInit, OnDestroy, AfterViewInit {

  public api_action = 'Data_tables.get_crm_entity';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public createSubscription: Subscription;
  public routerSubscription: Subscription;
  public forms: any;
  public types: any;
  public firstLoad = true;

  @ViewChild('clientTmpl', {static: true}) private clientTmpl: TemplateRef<any>;
  @ViewChild('idHead', {static: true}) private idHead: TemplateRef<any>;
  @ViewChild('captionHead', {static: true}) private captionHead: TemplateRef<any>;
  @ViewChild('addressHead', {static: true}) private addressHead: TemplateRef<any>;
  @ViewChild('innHead', {static: true}) private innHead: TemplateRef<any>;
  @ViewChild('authorHead', {static: true}) private authorHead: TemplateRef<any>;
  @ViewChild('managerHead', {static: true}) private managerHead: TemplateRef<any>;
  @ViewChild('organisation_statusHead', {static: true}) private organisation_statusHead: TemplateRef<any>;
  @ViewChild('organisation_statusTmpl', {static: true}) private organisation_statusTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, public fb: UntypedFormBuilder,
              private overlayWindowService: OverlayWindowService, public goBack: GoBackButtonService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'organisation_id': [''],
      'organisation_caption': [''],
      'organisation_address': [''],
      'organisation_inn': [''],
      'organisation_author': [''],
      'organisation_status_id': [''],
      'organisation_subagent_manager_id': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.router_param['page'] = 1;
      this.current_page = 1;
      if (this.firstLoad) {
        this.firstLoad = false;
      } else {
        this.changeForm(this.formGroup);
      }
    });
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.goBack.header = '<h1>Юридические лица</h1>';
    });
    this.updateForm(this.formGroup);
  }

  ngOnInit() {
    this.goBack.header = '<h1>Юридические лица</h1>';
    this.columns = [
      {'caption': '№', 'name': 'organisation_id', 'sortable': true, 'templateHead': this.idHead},
      {'caption': 'Ответственный', 'name': 'organisation_subagent_manager_id', 'sortable': true, 'templateHead': this.managerHead},
      {'caption': 'Название', 'name': 'organisation_caption', 'sortable': true, 'templateHead': this.captionHead},
      {'caption': 'Адрес', 'name': 'organisation_address', 'sortable': true, 'templateHead': this.addressHead},
      {'caption': 'ИНН', 'name': 'organisation_inn', 'sortable': true, 'templateHead': this.innHead},
      {'caption': 'Автор', 'name': 'organisation_author', 'sortable': true, 'templateHead': this.authorHead},
      {'caption': 'Статус', 'name': 'organisation_status', 'sortable': false, 'template': this.organisation_statusTmpl,
        'templateHead': this.organisation_statusHead},
      {'caption': '', 'template': this.clientTmpl}
    ];
    super.ngOnInit();
    this.forms = this.route.snapshot.data['forms'];
    this.types = this.route.snapshot.data['types'];
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {
      'organisation_id': this.formGroup.value.organisation_id,
      'organisation_caption': this.formGroup.value.organisation_caption,
      'organisation_address': this.formGroup.value.organisation_address,
      'organisation_inn': this.formGroup.value.organisation_inn,
      'organisation_author': this.formGroup.value.organisation_author,
      'organisation_subagent_manager_id': this.formGroup.value.organisation_subagent_manager_id,
      'organisation_status_id': this.formGroup.value.organisation_status_id,
    };
    this.goBack.header = '<h1>Юридические лица</h1>';
    return result;
  }

  createEntity() {
    const options = {'forms': this.forms, 'types': this.types};
    this.overlayWindowService.openComponentInPopup(EntityCreateComponent, options)
      .then((factory: ComponentRef<EntityCreateComponent>) => {
        this.createSubscription = factory.instance.onCreate.subscribe(_ => this.prepareQuery());
      });
  }

  ngOnDestroy() {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
